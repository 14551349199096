import { defineStore } from 'pinia';
import cloneDeep from 'lodash-es/cloneDeep';

import type { IPromocodeDetails, ISubscription } from '~/types/common';
import { getDividedPriceLabel } from '~/helpers/priceHelper';

export const useSubscriptionsStore = defineStore('useSubscriptionsStore', () => {
  const data = ref<ISubscription[] | null>(Array(3).fill({}));
  const pending = ref(true);
  const errors = ref([]);
  const promocodeData = ref<IPromocodeDetails | null>(null);

  // const baseSubscription = computed(() => data.value?.find(item => item.days_count === 30));
  // const getDiscountPercent = (value: number) => Math.round(value / (baseSubscription.value?.base_price ?? 0));

  const subscriptions = computed(() => {
    if (!data.value) return;
    return data.value
      .map(item => {
        const subscribe = {
          data: item as ISubscription,
          active: false,
          price: item?.base_price / 100,
          discount: item?.discounted_price / 100,
          currency: item?.currency?.sign,
          discountPrice: 0,
        };
        if (promocodeData.value?.tariffs?.includes(item.id)) {
          if (promocodeData.value?.discount_money) {
            subscribe.discountPrice = item.discounted_price / 100 - promocodeData.value?.discount_money / 100;
          }
          if (promocodeData.value?.discount_procent) {
            subscribe.discountPrice =
              item.discounted_price / 100 -
              Math.ceil(((item.discounted_price / 100) * promocodeData.value?.discount_procent) / 100);
          }
        }

        subscribe.note = getDividedPriceLabel(item.amount_per_day, item.currency.sign) + ' в день';

        if (item.days_count === 30) {
          return {
            ...subscribe,
            order: 2,
            title: 'На месяц',
            discountPercent: '',
          };
        }
        if (item.days_count === 90) {
          return {
            ...subscribe,
            order: 3,
            title: 'На 3 месяца',
            discountPercent: subscribe.discountPrice ? '' : -8,
          };
        }
        if (item.days_count === 365) {
          return {
            ...subscribe,
            order: 1,
            active: true,
            title: 'На год',
            discountPercent: subscribe.discountPrice ? '' : -25,
          };
        }
      })
      .filter(subscribe => subscribe)
      .sort((a, b) => (a!.order < b!.order ? -1 : 1));
  });

  const setSubscriptions = (payload?: ISubscription[] | null) => {
    if (!payload) return;
    data.value = cloneDeep(payload);
    pending.value = false;
  };

  return {
    pending,
    errors,
    subscriptions,
    promocodeData,
    setSubscriptions,
  };
});
