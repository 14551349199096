<script lang="ts" setup></script>

<template>
  <div class="subscriptions-skeleton">
    <div class="subscriptions-skeleton__item active">
      <v-skeleton h="4rem" />
      <v-skeleton h="6rem" />
      <v-skeleton h="2rem" />
      <v-skeleton h="4rem" />
    </div>
    <div class="subscriptions-skeleton__item">
      <v-skeleton h="2rem" />
      <v-skeleton h="4rem" />
      <v-skeleton h="2rem" />
      <v-skeleton h="3rem" />
    </div>
    <div class="subscriptions-skeleton__item">
      <v-skeleton h="2rem" />
      <v-skeleton h="4rem" />
      <v-skeleton h="2rem" />
      <v-skeleton h="3rem" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .subscriptions-skeleton {
    display: flex;
    align-items: center;
    gap: 24px;
    &__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 334px;
      height: 334px;
      padding: 24px;
      border-radius: 24px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.02) 100%), #0b0b0b;
      &.active {
        width: 484px;
        height: 432px;
      }
    }
    @media (max-width: $tablet-small) {
      display: grid;
      justify-content: center;
      grid-template-columns: minmax(0, 500px);
      &__item {
        width: 100%;
        &.active {
          width: 100%;
        }
      }
    }
    @media (max-width: $retina) {
      padding-bottom: 24px;
      &__item {
        &.active {
          height: 334px;
        }
      }
    }
  }
</style>
